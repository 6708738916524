/* This example requires Tailwind CSS v2.0+ */
import { LifebuoyIcon, NewspaperIcon, PhoneIcon } from '@heroicons/react/24/outline'

const supportLinks = [
  {
    name: 'Interessenten',
    href: 'mailto:sales@codingarage.de',
    email: 'sales@codingarage.de',
    description:
      'Du hast eine Idee und spielst mit dem Gedanken diese mit uns zu realisieren? Dann bist du bei dieser Adresse richtig:',
    icon: PhoneIcon,
  },
  {
    name: 'Technischer Support',
    email: 'support@codingarage.de',
    href: 'mailto:support@codingarage.de',
    description:
      'Du bist bereits Kunde und du hast eine technische Frage oder ein Problem? dann bist du bei dieser Adresse richtig:',
    icon: LifebuoyIcon,
  },
  {
    name: 'Allgemeines',
    email: 'info@codingarage.de',
    href: 'mailto:info@codingarage.de',
    description:
      'Dein Anliegen ist eine allgemeine Frage oder passt nicht zu den beiden anderen Kategorien? Dann bist du bei dieser Adresse richtig:',
    icon: NewspaperIcon,
  },
]

export default function Contacts() {
  return (
   
    <section id="contact">
    <div className="bg-white">
      {/* Header */}
      <div className="relative bg-gray-800 pb-32">
        <div className="absolute inset-0">
          <div
            className="h-full w-full object-cover bg-[#64C5A7]"
            
          ></div>
         
        </div>
        <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">Kontakt</h1>
         
        </div>
      </div>

      {/* Overlapping cards */}
      <section 
        className="relative z-10 mx-auto -mt-32 max-w-7xl px-4 pb-32 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Kontaktiere uns
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} className="flex flex-col rounded-2xl bg-white shadow-xl">
              <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
                <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-gray-600 p-5 shadow-lg">
                  <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
            <br/>
                <h4 className="text-xl font-medium text-gray-700">{link.email}</h4>

              </div>
              
            </div>
          ))}
        </div>
      </section>
    </div>
    </section>
  )
}
