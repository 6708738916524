
const posts = [
    {
        title: 'Web-Applikationsentwicklung',
        href: '#',
        description:
            'Die meisten Webapplikationsprojekte liegen bei uns im mittleren, vierstelligen Bereich. Dies hängt vom Umfang der Neuentwicklung ab und kann in beide Richtungen stark variieren. Wende dich gerne kostenlos an uns.',
    },
    {
        title: 'App-Entwicklung',
        href: '#',
        description: 'Die meisten Projekte, bei denen eine App entwickelt wird, liegen bei uns im höheren, vierstelligen Bereich. Dies variiert ebenfalls stark in beide Richtungen. Komm gerne auf uns zu.',

    },
    {
        title: 'Onlineshop',
        href: '#',
        description:
            'Onlineshop Projekte liegen meistens im niedrigen, vierstelligen Bereich. ',

    },
    {
        title: 'Startup-Rabatt',
        href: '#',
        description:
            'Wir wissen, dass gerade am Anfang das Geld knapp ist. Deshalb bieten wir, nach erfolgreicher Prüfung einiger Kriterien, einen Startup-Rabatt an. Hierbei reduzieren wir den Preis signifikant und werden dafür mit Anteilen an dem Unternehmen beteiligt. Wenn diese Variante interessant für dich ist, melde dich gerne!',

    },
]

export default function Prices() {
    return (
        <section id="pricing">
            <div className="bg-white px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
                <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Preise</h2>
                        <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
                            <p className="text-xl text-gray-500">Sämtliche Projekte werden bei uns zum Festpreis entwickelt.
                             Dieser wird im direkten Gespräch mit dir berechnet und du bekommst ein Angebot zugesandt. Zur besseren Einschätzung geben wir dir nachfolgend einige Preiserfahrungen.</p>
                          
                        </div>
                    </div>
                    <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
                        {posts.map((post) => (
                            <div key={post.title}>
                                
                                <a  className="mt-2 block">
                                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                                </a>
                                
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
