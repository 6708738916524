import './App.css';
import Hero from './components/Hero';
import Features from './components/Features';
import Products from './components/Products';
import Vision from './components/Vision';
import Contacts from './components/Contacts';
import Footer from './components/Footer';
import CTA from './components/CTA';
import Blog from './components/Blog';
import Prices from './components/Prices';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';



function App() {

  return (
    <>
      <div className="App">
        <Helmet>
          <meta
            name="description"
            content="CodinGarage ist ein Softwareentwicklungsunternehmen aus Köln. Wir realisieren deine Idee mit neusten Technologien."
          />
          <meta
            name="keywords"
            content="codingarage, coding garage, webagentur, köln, leverkusen, software, softwareentwickler"
          />
          <meta
            property="og:title"
            content="CodinGarage - Softwareentwicklung aus Köln"
          />
          
          <meta
            property="og:description"
            content="CodinGarage ist ein Softwareentwicklungsunternehmen aus Köln. Wir realisieren deine Idee mit neusten Technologien."
          />

         
          <meta property="og:image" content="./ " />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="CodinGarage Softwareentwicklung" />
          <meta property="og:url" content="https://www.codingarage.de/" />

          <title>CodinGarage - Softwareentwicklung aus Köln</title>
        </Helmet>
      </div>

      <Hero />

      <Vision />
      <Products />
      <Features />
      

      <Prices />
      <Blog />


      <Contacts />

      <CTA />

      <Footer />


    </>
  );
}

export default App;
