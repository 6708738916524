/* This example requires Tailwind CSS v2.0+ */
export default function Vision() {
    return (
      <div className="bg-[#64C5A7] mt-20">
        <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            <span className="block">Startups aufgepasst!</span>
            
          </h2>
          <p className="mt-4 text-lg leading-6 text-white">
            Wir entwickeln leidenschaftlich Projekte von Grund auf. Das bedeutet, wenn du eine coole Idee hast, werden wir dir beim Durchstarten helfen. Schau dir gerne unsere Leistungen an und wirf Mal einen Blick auf den <b className="text-lg">Startup-Rabatt</b>. <br/>Noch nicht gegründet? Gar kein Problem, melde dich gerne bei uns!
          </p>
          <a
            href="#products"
            className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-[#64C5A7] hover:bg-[#c8e9de] sm:w-auto"
          >
            Wobei wir helfen 
          </a>
        </div>
      </div>
    )
  }
  