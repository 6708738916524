/* This example requires Tailwind CSS v2.0+ */
const posts = [

  {
    title: 'Was ist ein Tech Stack?',
    href: 'https://blog.codingarage.de/4f1a37f2-ebbe-4837-ab08-688d4631cc04/index.html',
    category: { name: 'Tech', href: '#', color: 'bg-green-100 text-green-800' },
    description:
      'Was ist ein Tech Stack und worauf muss man bei der Wahl des richtigen Tech Stacks achten?',
    date: '24.09.2022',
    datetime: '2020-09-24',
    author: {
      name: 'Titus',
      href: '',
    },

  },

  {
    title: 'Rechtspflichten im eCommerce',
    href: 'https://blog.codingarage.de/d5cd92bc-e966-4d27-b385-e014ea7a14c5/index.html',
    category: { name: 'Deep Dive', href: '#', color: 'bg-indigo-100 text-indigo-800' },
    description:
      'Dieser Beitrag setzt sich mit den Pflichten auseinander, welche sich aus §312 - 312k BGB ergeben.',
    date: '21.09.2022',
    datetime: '2020-09-21',
    author: {
      name: 'Alex',
      href: '',
    },

  },
 
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Blog() {
  return (
    <div className="bg-white px-4 pt-4 pb-20 sm:px-6 lg:px-8 lg:pt-4 lg:pb-28">
      <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Neuste Blogbeiträge</h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Schau doch mal in unserem Blog vorbei. Wir berichten über Tech-Themen, Gründung in Deutschland und Startups allgemein.
          </p>
        </div>
        <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.map((post) => (
            <div key={post.title}>
              <div>

                <a href={post.category.href} className="inline-block">
                  <span
                    className={classNames(
                      post.category.color,
                      'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium'
                    )}
                  >
                    {post.category.name}
                  </span>
                </a>

              </div>

              <a href={post.href} className="mt-4 block">
                <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                <p className="mt-3 text-base text-gray-500">{post.description}</p>
              </a>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <a href={post.author.href}>
                    <span className="sr-only">{post.author.name}</span>

                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    <a href={post.author.href}>{post.author.name}</a>
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={post.datetime}>{post.date}</time>

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
