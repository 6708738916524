import Lottie from 'lottie-react'
import shop from '../shop.json'
import dev from '../dev.json'
import web from '../web.json'

/* This example requires Tailwind CSS v2.0+ */
const posts = [
  {
    title: 'Web-Applikationen',
    href: '#',
    category: { name: 'Article', href: '#' },
    description:
      'Wir entwickeln aller Art Web-Applikationen. Hier werden wir individuell mit dir abstimmen, ob wir deine Idee realisieren können.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      web,
    readingTime: '6 min',
    author: {
      name: 'Roel Aufderehar',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'App-Entwicklung',
    href: '#',
    category: { name: 'Video', href: '#' },
    description:
      'Wir wandeln die Web-Applikation in eine Cross-Platform App, damit sie auf verschiedenen Betriebssystemen laufen kann.',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
      dev,
    readingTime: '4 min',
    author: {
      name: 'Brenna Goyette',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Onlineshops',
    href: '#',
    category: { name: 'Case Study', href: '#' },
    description:
      'Neben der Neuentwicklung von Software helfen wir dir auch bei dem Aufbau deines Webshops.',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      shop,
    readingTime: '11 min',
    author: {
      name: 'Daniela Metz',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
]

export default function Products() {
  return (
    <section id="products">
    <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Diese Ideen entwickeln wir</h2>
        
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div className="flex-shrink-0">
                <Lottie animationData={post.imageUrl} loop={true} />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <a className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </a>
                </div>
               
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </section>
  )
}
