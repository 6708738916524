/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Lottie from 'lottie-react'
import rocket  from '../hero-rocket.json'
import logo from '../logo.png'
import logo_small from '../logo_small.png'

const navigation = [
    { name: 'Leistungen', href: '#features' },
    { name: 'Preise', href: '#pricing' },
    { name: 'Blog', href: 'https://blog.codingarage.de/' },
    { name: 'Kontakt', href: '#contact' },
]



export default function Hero() {
  return (
    <>
    <div className="relative h-screen no-rocket:h-fit overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
          

          <Popover>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
                  <div className="flex w-full items-center justify-between md:w-auto">
                    <a href="#">
                      <span className="sr-only">CodinGarage</span>

                        <img src={logo} className="h-16 sm:h-20" />
                        
                     
                      {/*
                      
                      <h3 className='text-2xl text-gray-700 tracking-widest font-semibold'>Codin<span className='text-3xl text-[#64C5A7]'>G</span>arage</h3>
                      */}
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-xl text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}
                 
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                    <span className="sr-only">CodinGarage</span>
                    <img src={logo} className="h-16" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-44">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Realize Your Idea.</span>{' '}
                <br/>
                <span className="block text-[#64C5A7] xl:inline">with CodinGarage</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                Das Entwicklerteam auf das du schon immer gewartet hast. Wir helfen dir dabei, mit deiner Idee durchzustarten. Nutze die Chance!
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#features"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#64C5A7] px-8 py-3 text-base font-medium text-white hover:bg-[#30c294] md:py-4 md:px-10 md:text-lg"
                  >
                    Leistungen
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#contact"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#b8eede] px-8 py-3 text-base font-medium text-[#369c7c] hover:bg-[#98f7d9] md:py-4 md:px-10 md:text-lg"
                  >
                    Kontaktieren
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        
        <Lottie className='no-rocket:h-2/6 h-5/6' animationData={rocket} loop={true}/>
        {/*
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-4/5 lg:w-4/5"
          src={process.env.PUBLIC_URL + "/hero-rocket.json"}
          alt=""
        />
                    */}
      </div>
    </div>
    </>
  )
}
